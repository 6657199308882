import React from "react";
import { graphql } from 'gatsby'
import * as mainContent from "../components/what-is-data-story-telling/mainContent";
import Resource from "../components/what-is-data-story-telling/resource";
import MainHeader from "../components/what-is-data-story-telling/mainHeader";
import CommonQuestion from "../components/common-question";
import { resetWebflow } from "../utils/common";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Schema from "../components/common/schema";
import Notification from "../components/header/notification";
import Layout from "../layouts";

export default ({
  data: {
    datoCmsWhatIsTheStoryTelling: {
      seoMetaTags,
      mainContent: mainContentData,
      hiddenContent: hiddenContentData,
      resources,
      commonQuestions,
      schema,
      notificationPage
    },
    allDatoCmsResourceCategory: { edges: resourceCategory },
    allDatoCmsNotification: { edges: notifications }
  }
}) => {
  resetWebflow("5aabd62c51e22ed9be346d10");

  resourceCategory = resourceCategory.map(item => item.node);
  const mainContentTmpl = mainContent.render(
    mainContentData,
    hiddenContentData
  );
  return (
    <Layout>
      <Schema data={schema} />
      <Notification
        notifications={notifications}
        linkedPage={notificationPage}
      />
      <HelmetDatoCms seo={seoMetaTags} />
      <header className="main-header about">
        {mainContentData.map(block => (
          <React.Fragment key={block.id}>
            {block.model && block.model.apiKey === "main_summary" && (
              <MainHeader data={block} />
            )}
            {block.model && block.model.apiKey === "main_photo" && (
              <div
                className="storytelling-illustration"
                style={{ backgroundImage: `url(${block.photo.url})` }}
              />
            )}
          </React.Fragment>
        ))}
        {mainContentTmpl}
      </header>
      <section className="white-section gray">
        <div className="container">
          <Resource category={resourceCategory} detail={resources} />
          <div className="hg-spacing" />
          <CommonQuestion data={commonQuestions} />
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query DataStoryTellingQuery {
    datoCmsWhatIsTheStoryTelling {
      notificationPage {
        id
        page
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      mainContent {
        ... on DatoCmsMainSummary {
          id
          title
          description
          model {
            apiKey
          }
        }
        ... on DatoCmsMainPhoto {
          id
          photo {            
            url
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsParagraph {
          id
          text
          model {
            apiKey
          }
        }
        ... on DatoCmsHeaderWithDivider {
          id
          quote
          quoteOwner
          model {
            apiKey
          }
        }
        ... on DatoCmsExampleBox {
          id
          name
          description
          photo {
            alt
            url
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsVideo {
          id
          video {
            url
          }
          model {
            apiKey
          }
        }
      }
      hiddenContent {
        ... on DatoCmsParagraph {
          id
          text
          model {
            apiKey
          }
        }
      }
      resources {
        ... on DatoCmsSummary {
          title
          model {
            apiKey
          }
        }
        ... on DatoCmsResourceDetail {
          id
          thumbnail {
            alt
            url
          }
          title
          source
          cta
          category {
            id
            title
          }
          model {
            apiKey
          }
        }
      }
      commonQuestions {
        ... on DatoCmsQuestion {
          id
          question
          answer
          model {
            apiKey
          }
        }
      }
      schema {
        ... on DatoCmsSchemaDetail {
          id
          jsonSchema
        }
      }      
    }
    allDatoCmsResourceCategory {
      edges {
        node {
          id
          title
          icon {
            alt
            url
          }
          model {
            apiKey
          }
        }
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;
