import React from "react";
import ReactMarkdown from "react-markdown";

export default ({ data }) => {
  return (
    <React.Fragment>
      <div className="quote-divider top" />
      <div className="pull-quote">
        <ReactMarkdown source={data.quote} escapeHtml={false} />
      </div>
      {data.quoteOwner && <div className="quote-owner">{data.quoteOwner}</div>}
      <div className="quote-divider bottom" />
    </React.Fragment>
  );
};
