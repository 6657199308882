import React from "react";
import { getSafe } from "../../utils/common";

export default ({ category, detail }) => {
  let summaryModelType = ["summary", "resource_summary"];
  let detailModelType = ["resource_detail", "integration_resource_detail"];
  let resourceSummary = detail.find(
    item => item.model && summaryModelType.includes(item.model.apiKey)
  );
  let resourceDetail = detail.filter(
    item => item.model && detailModelType.includes(item.model.apiKey)
  );

  return (
    <React.Fragment>
      <h2 className="h2">
        {getSafe(() => resourceSummary.title) || getSafe(() => resourceSummary.sectionTitle)}
      </h2>
      <div className="sm-spacing" />
      <div className="sm-spacing" />
      <div data-duration-in="300" data-duration-out="100" className="w-tabs">
        <div className="tab-story-container w-tab-menu">
          {category.map((item, idx) => renderTab(item, idx, resourceDetail))}
        </div>
        <div className="w-tab-content">
          {category.map((cat, idx) => (
            <div
              key={cat.id}
              data-w-tab={cat.title}
              className={`w-tab-pane ${idx === 0 ? "w--tab-active" : ""}`}
            >
              <div className="flex-container top">
                {resourceDetail.map(
                  item =>
                    item.category.id === cat.id && (
                      <a
                        key={item.id}
                        href={item.cta}
                        target="_blank"
                        className="_3cols-box reduced-margin left w-inline-block"
                      >
                        <img
                          src={item.thumbnail.url}
                          alt={item.thumbnail.alt}
                        />
                        <div className="sub-text">{item.source}</div>
                        <h5 className="h5">{item.title}</h5>
                      </a>
                    )
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

function renderTab(item, idx, resourceDetail) {
  if (!(resourceDetail && item)) {
    return "";
  }
  let isCurrentTabHasItem = resourceDetail.some(
    res => res.category.id === item.id
  );
  return (
    isCurrentTabHasItem && (
      <a
        key={item.id}
        data-w-tab={item.title}
        className={`tab-story w-inline-block w-tab-link ${
          idx === 0 ? "w--current" : ""
        }`}
      >
        <img
          src={item.icon.url}
          alt={item.icon.alt}
          width="40"
          height="40"
          className="tab-story-img"
        />
        <div>{item.title}</div>
      </a>
    )
  );
}
