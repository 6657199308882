import React from "react";
import ReactMarkdown from "react-markdown";

export function render(data) {
  return (
    <div
      data-w-id="210f51e2-3b6d-1712-969f-2f88d4f22698"
      style={{ display: "none" }}
      className="continue-to-read-content"
    >
      {data.map(block => (
        <React.Fragment key={block.id}>
          {block.model && block.model.apiKey === "paragraph" && (
            <ReactMarkdown source={block.text} escapeHtml={false} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
