import React from "react";
import ReactMarkdown from "react-markdown";
import { resizeAndConvertImage } from "../../utils/common";

export default ({ data }) => {
  return (
    <React.Fragment>
      <h5 className="h5">{data.name}</h5>
      <div className="sm-spacing" />
      <img
        src={`${data.photo.url}${resizeAndConvertImage(1400)}`}
        width="700"
        alt={data.photo.alt}
        className="blog-img"
      />
      <div className="p blog">
        <ReactMarkdown source={data.description} escapeHtml={false} />
      </div>
      <div className="sm-spacing" />
    </React.Fragment>
  );
};
