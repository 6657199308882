import React from "react";
import HeaderWithDivider from "../header-with-divider";
import ExampleBox from "../what-is-data-story-telling/exampleBox";
import ReactMarkdown from "react-markdown";
import SocialShare from "../common/social-share"
import * as hiddenContent from "../../components/what-is-data-story-telling/hiddenContent";

export function render(data, hiddenContentData) {
  const hiddenContentTmpl = hiddenContent.render(hiddenContentData);

  return (
    <div className="container grid">
      <SocialShare />
      <div
        data-w-id="1cdb0609-5797-4ec2-2433-4b7250f7e631"
        className="post-container"
      >
        {data.map(block => (
          <React.Fragment key={block.id}>
            {block.model && block.model.apiKey === "header_with_divider" && (
              <HeaderWithDivider data={block} />
            )}
            {block.model && block.model.apiKey === "example_box" && (
              <ExampleBox data={block} />
            )}
            {block.model && block.model.apiKey === "paragraph" && (
              <ReactMarkdown source={block.text} escapeHtml={false} />
            )}
            {block.model && block.model.apiKey === "video" && (
              <div className="blog-video w-video w-embed" style={{ paddingBottom: "56.25%"}}>
                <iframe
                  src={block.video.url}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                />
              </div>
            )}
          </React.Fragment>
        ))}
        <a
          id="continueReadingBtn"
          href="#"
          style={{ display: "inline-block" }}
          className="btn outline color no-margin-left w-button"
        >
          Continue Reading
        </a>
        <div className="sm-spacing" />
        {hiddenContentTmpl}
      </div>
    </div>
  );
}
