import React from "react";
import ReactMarkdown from "react-markdown";

export default ({ data }) => {
  return (
    <div className="container grid story">
      <h1 className="h-nofixedwidth">{data.title}</h1>
      <div className="sm-spacing" />
      <div className="p center-align">
        <ReactMarkdown source={data.description} escapeHtml={false} />
      </div>
    </div>
  );
};
